<template>
    <modal ref="modalActualizarDatos" titulo="Solicitud de actualización de datos" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-auto px-3" />
            <div class="col">
                <div class="row mx-0">
                    <img :src="datos.foto_admin" class="img-user obj-cover rounded-circle" />
                    <div class="col px-2 mt-1">
                        <p class="text-general f-16 f-500">{{ datos.admin || "No registra" }}</p>
                        <p class="text-general f-16">Encargado</p>
                    </div>
                    <i class="icon-fecha-inicio text-general f-20" />
                    <div class="col px-0 mt-1">
                        <p class="text-general f-16 f-500">Actualización</p>
                        <p class="text-general f-16">{{ formatearFecha(datos.fecha_inicio) }} - {{ formatearFecha(datos.fecha_fin) }}</p>
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <div class="row mx-0">
                    <div style="width:55px;" />
                    <div class="col px-2 mt-1">
                        <p class="text-general f-16 f-500 mb-2">Datos solicitados a actualizar</p>
                        <div class="row mx-0 align-items-center mb-2">
                            <div class="col-2 px-0 text-center">
                                <i v-if="datos.nacimiento==1" class="icon-ok-circled-outline text-green f-20" />
                            </div>
                            <div :class="estadosLabel(datos.nacimiento)">
                                Fecha nacimiento
                            </div>        
                        </div>
                        <div class="row mx-0 align-items-center mb-2">
                            <div class="col-2 px-0 text-center">
                                <i v-if="datos.genero==1" class="icon-ok-circled-outline text-green f-20" />
                            </div>
                            <div :class="estadosLabel(datos.genero)">
                                Genero
                            </div>        
                        </div>
                        <div class="row mx-0 align-items-center mb-2">
                            <div class="col-2 px-0 text-center">
                                <i v-if="datos.direccion==1" class="icon-ok-circled-outline text-green f-20" />
                            </div>
                            <div :class="estadosLabel(datos.direccion)">
                                Dirección
                            </div>        
                        </div>
                        <div v-if="this.$route.name==='admin.datos-cuentas.lanzamientos.leecheros'" class="row mx-0 align-items-center mb-2">
                            <div class="col-2 px-0 text-center">
                                <i v-if="datos.facturacion==1" class="icon-ok-circled-outline text-green f-20" />
                            </div>
                            <div :class="estadosLabel(datos.facturacion)">
                                Facturación
                            </div>        
                        </div>
                        <div class="row mx-0 align-items-center mb-2">
                            <div class="col-2 px-0 text-center">
                                <i v-if="datos.password==1" class="icon-ok-circled-outline text-green f-20" />
                            </div>
                            <div :class="estadosLabel(datos.password)">
                                Contraseña
                            </div>        
                        </div>
                    </div>
                    <i class="icon-cedis text-general f-17" />
                    <div class="col px-0 mt-1">
                        <p class="text-general f-16 f-500 mb-1">Cedis donde aplica</p>
                        <p v-for="(c,d) in datos.cedis" :key="d" class="text-general f-16">
                            {{ c.cedis }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 align-items-center">
                    <div style="width:55px;" />
                    <div class="col-auto px-0 text-general f-16">
                        Alcance
                    </div>
                    <div class="box-info d-flex flex-column justify-content-center ml-3">
                        <p class="text-general f-30 f-600 text-center">
                            {{ datos.rechazadas }}
                        </p>
                        <p class="text-general f-16 text-center">
                            Omitieron
                        </p>
                    </div>
                    <div class="box-info d-flex flex-column justify-content-center ml-3">
                        <p class="text-general f-30 f-600 text-center">
                            {{ datos.aceptadas }}
                        </p>
                        <p class="text-general f-16 text-center">
                            Actualizaron
                        </p>
                    </div>
                    <div class="box-info d-flex flex-column justify-content-center ml-3">
                        <p class="text-general f-30 f-600 text-center">
                            {{ datos.faltantes-(datos.aceptadas+datos.rechazadas) }}
                        </p>
                        <p class="text-general f-16 text-center">
                            No abrieron
                        </p>
                    </div>
                </div> 
            </div>
            <div class="col-auto">
                <div class="row mx-0 justify-center align-items-center text-general">
                    <i :class="`${estadosLeecheros(datos.estado)} f-20`" />
                    <p v-if="datos.estado==1">Vigente</p>
                    <p v-if="datos.estado==2">Cancelada</p>
                </div>
                <div class="row mx-0 mt-3">
                    <div v-if="datos.estado==1" class="btn-general px-4 text-white f-14" @click="cancelarSolicitud(datos.id)">
                        Cancelar
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import datosCuentas from '~/services/DatosCuentas/datosCuentas'
export default {
    data(){
        
        return {
            url_person: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            datos: {}

        }

    },
    methods: {
        toggle(row){
            this.datos = row
            this.$refs.modalActualizarDatos.toggle();
        },
        estadosLeecheros(state){
            switch (state){
            case 1:
                return 'icon-ok-circled-outline text-green';
            case 2:
                return 'icon-cancel-circled-outline text-general-red';
            }
        },
        estadosLabel(state){
            switch (state){
            case 1:
                return "col px-0 text-general";
            case 2:
                return "col px-0 text-general2";
            }
        },
        async cancelarSolicitud(id){
            try {
                const {data} = await datosCuentas.cancelarActualizacion(id)
                this.notificacion("Éxito","Solicitud cancelada correctamente","success")
                this.$emit('update')
                this.$refs.modalActualizarDatos.toggle();
            }catch (error){
                return this.error_catch(error)
            }
            
        }
    }
}
</script>
<style lang="scss" scoped>
.img-user{
    width: 55px;
    height: 55px;
    border: 1px solid hsl(0, 0%, 96%);
}
.box-info{
    width: 119px;
    height: 90px;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
}
</style>